import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Container from '@mui/material/Container';
import ProjectCarousel from './Carousel';
import { Row, Col } from 'reactstrap';

const ImageOverlay = ({ project, onClose }) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const width = window.innerWidth;
        if (width >= 1024) return 'desktop';
        if (width > 500 && width < 1024) return 'tablet';
        return 'mobile';
    }

    const featureList = () => {
        return(
            <div>
                <p>Features</p>
                <ul className='featurelist'>
                {project.features.map(feature => (
                    <li>{feature}</li>
                ))}
                </ul>
            </div>
        )
    }

    const techList = () => {
        return(
            <div>
                <p>Technologies</p>
                <ul className='techlist'>
                {project.technologies.map(tech => (
                    <li>{tech}</li>
                ))}
                </ul>
        </div>
        )
    }
    useEffect(() => {
        function handleResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='pageOverlay'>
            <button
                onClick={onClose}
                className='close-btn'
            >
                <FontAwesomeIcon icon={faTimes} size='xl' />
            </button>
            <div
                style={{
                    position: 'relative',
                    maxWidth: '90%',
                    maxHeight: '90%',

                }}
            >

                <Container maxWidth="md" className={`${windowSize !== 'desktop' && 'mt-5'}`}>
                <div className='d-flex'>
                    <h1 className='project-title'>{project.title}</h1>
                    <a href={project.github} target='_blank' rel='noreferrer' style={{ alignSelf: 'flex-end', paddingBottom: '6px'}}>
                        <i className="devicon-github-plain colored" style={{ color: 'white', textAlign: 'center', paddingBottom: '2px'}}></i>
                    </a>
                </div>
                <hr style={{ color: 'white', marginTop: '0'}}/>
                    <p>{project.description}</p>
                    {windowSize === 'desktop' &&
                        <Row  className='mt-4'>
                            <Col>
                                {featureList()}
                                {techList()}
                            </Col>
                            <Col>
                                <ProjectCarousel images={project.imgArr} />
                            </Col>
                        </Row>                
                    }
                    {windowSize === 'tablet' &&
                        <div>
                            <Row  className='mt-4'>
                                <Col>
                                    {featureList()}
                                </Col>
                                <Col>
                                    {techList()}
                                </Col>
                            </Row> 
                            <ProjectCarousel images={project.imgArr} />
                        </div>
                    }
                    {windowSize === 'mobile' &&
                        <div className='my-4'>
                            {featureList()}
                            {techList()}
                            <ProjectCarousel images={project.imgArr} />
                        </div>
                    }
                </Container>
            </div>
        </div>
    );
};

export default ImageOverlay;
