import React from 'react';
import { Container} from 'reactstrap';

const Contact = () => {
    return (
        <Container className='text-center page'>
            <h1 className='mb-5'>Contact</h1>
            <p>Contact me at <a href='mailto:jeffmcgwn@gmail.com'>jeffmcgwn@gmail.com</a></p>

        </Container>
    );
}

export default Contact;
