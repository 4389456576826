import logo from './logo.svg';
import './App.css';
import './Animations.css';
import DenseAppBar from './components/DenseAppBar';
import Portfolio from './components/Portfolio';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';

function App() {
  return (
    <div className="App">
      <div className='placeholder'/>
      <DenseAppBar />
      <Router>
        <Routes>

          <Route path="/portfolio" Component={Portfolio} />
          <Route path="/" Component={About} />
          <Route path="/About" Component={About} />
          <Route path="/Contact" Component={Contact} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
