import * as React from 'react';
import { useEffect } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';
import { Container } from '@mui/material';


function ProjectCarousel({ images }) {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    useEffect(() => {
        setActiveIndex(0);
    }, [images])
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      }
      
      const openImageInNewTab = (img) => {
        const newWindow = window.open(img, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      }

      const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
      }
      
      const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
      }
      

      return (
        <Container maxWidth="sm" style={{ gap: '20px' }}>
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={goToIndex} />
          {images.map((item, index) => (
            <CarouselItem
              key={index}
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}
            >
              <img src={item.prev} onClick={() => openImageInNewTab(item.img)} className='carousel'/>
            </CarouselItem>
          ))}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
        </Container>
      );
      
}

export default ProjectCarousel;
