import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import me from '../assets/me.jpg'
import { useEffect, useState } from 'react';
import { render } from '@testing-library/react';

const About = () => {
    const [isOnTablet, setIsOnTablet] = useState(window.innerWidth > 575 && window.innerWidth < 768);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const renderTechnologies = () => {
        return (
            <div>
                <p className='mb-0'>Technologies:</p>
                <div className='d-flex w-100 icons mb-4'>
                    <i className="devicon-react-original colored"></i>
                    <i className="devicon-typescript-plain colored"></i>
                    <i className="devicon-javascript-plain colored"></i>
                    <i className="devicon-csharp-plain colored"></i>
                    <i className="devicon-nodejs-plain colored"></i>
                    <i className="devicon-express-original colored"></i>
                    <i className="devicon-mongodb-plain colored"></i>
                    <i className="devicon-python-plain colored"></i>
                    <i className="devicon-swift-plain colored"></i>
                    <i className="devicon-kotlin-plain colored"></i>
                    <i className="devicon-materialui-plain colored"></i>
                    <i className="devicon-bootstrap-plain colored"></i>
                    <i className="devicon-dotnetcore-plain colored"></i>
                </div>
            </div>
        )
    }

    const renderOtherSkills = () => {
        return (
            <div>
                <p className='mt-2 mb-0'>Other skills:</p>
                <div className='d-flex w-100 icons'>
                    <i className="devicon-photoshop-plain colored" style={{ color: 'blue'}}></i> {/* Added Photoshop icon */}
                    <i className="devicon-github-plain colored" style={{ color: 'white'}}></i> {/* Added GitHub icon */}
                    <i className="devicon-sourcetree-plain colored"></i> {/* Added Sourcetree icon */}
                    <i className="devicon-jira-plain colored"></i> {/* Added Jira icon */}
                    <i className="devicon-agile-plain colored"></i> {/* Added Agile icon */}
                </div>
            </div>
        )
    }

    function getWindowSize() {
        return window.innerWidth > 575 && window.innerWidth < 768;
    }

    useEffect(() => {
        function handleResize() {
            setIsOnTablet(getWindowSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <Container className='page'>
            <h1 className='text-center mb-5'>About Me</h1>
            {!isOnTablet ?
            <Row>
                <Col sm={6}>
                    <img src={me} alt='Jeff McGowan' style={{ width: '100%'}} className='me'/>
                </Col>
                <Col sm={6}>
                    <p className='intro-column'>Hello! I'm Jeff McGowan, a seasoned full-stack developer with a passion for crafting exceptional digital experiences. With over five years of experience in the industry, I've honed my skills to specialize in modern front-end technologies.</p>
                    {renderTechnologies()}
                    {renderOtherSkills()}
                </Col>
            </Row>
            : 
            <div>
                <Row>
                    <Col sm={6}>
                        <img src={me} alt='Jeff McGowan' style={{ width: '100%'}}/>
                    </Col>
                    <Col sm={6}>
                        <p className='intro-column'>Hello! I'm Jeff McGowan, a seasoned full-stack developer with a passion for crafting exceptional digital experiences. With over five years of experience in the industry, I've honed my skills to specialize in modern front-end technologies.</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        {renderTechnologies()}
                    </Col>
                    <Col>
                        {renderOtherSkills()}
                    </Col>
                </Row>
            </div>
            }
            {/* Rest of the content */}
        </Container>
    );
}

export default About;
