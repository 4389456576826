import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import bball1 from '../assets/bball1.png';
import bball2 from '../assets/bball2.png';
import bball3 from '../assets/bball3.png';
import bball2Prev from '../assets/bball2prev.png';
import bball3Prev from '../assets/bball3prev.png';
import bballPrev from '../assets/bballPrev.png';
import cabPort from '../assets/cabPort.png';
import cabPortPrev from '../assets/cabPortPrev.png';
import lamePrev from '../assets/lamePrev.png';
import lgsite1 from '../assets/lgsite1.png';
import lgsite2 from '../assets/lgsite2.png';
import lgsite1Prev from '../assets/lgsite1Prev.png';
import lgsite2Prev from '../assets/lgsite2Prev.png';
import soluspedalsport from '../assets/soluspedalsport.png';
import solusport from '../assets/solusport.png';
import solusPedalsPortPrev from '../assets/solusPedalsPortPrev.png';
import solusPortPrev from '../assets/solusPortPrev.png';
import solusPrev from '../assets/solusPrev.png';
import wrasslin1 from '../assets/wrasslin1.png';
import wrasslin2 from '../assets/wrasslin2.png';
import wrasslin3 from '../assets/wrasslin3.png';
import wrasslin4 from '../assets/wrasslin4.png';
import wrasslin2Prev from '../assets/wrasslin2Prev.png';
import wrasslin3Prev from '../assets/wrasslin3Prev.png';
import wrasslin4Prev from '../assets/wrasslin4Prev.png';
import wrasslinPrev from '../assets/wrasslinPrev.png';

import ImageOverlay from './ImageOverlay';

export default function Portfolio() {
  const [imgDisplay, setImgDisplay] = useState(false);
  const [isOnTablet, setIsOnTablet] = useState(window.innerWidth < 575);

  const [project, setProject] = useState({
    img: '',
    title: '',
    description: '',
    imgArr: [''],
    features: [''],
    technologies: ['']
  });
  const [hoverIndex, setHoverIndex] = useState(null);

  const showOverlay = (item) => {
    setImgDisplay(true);
    setProject(item);
  };
  function getWindowSize() {
    return window.innerWidth < 575;
}

useEffect(() => {
    function handleResize() {
        setIsOnTablet(getWindowSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}, []);
  return (
    <div className='image-list page' style={{ overflow: 'hidden'}}>
      <h1 style={{ textAlign: 'center'}}>Portfolio</h1>
      <div style={{ display: imgDisplay ? 'block' : 'none', zIndex: '1000'}}>
        <ImageOverlay project={project} onClose={() => setImgDisplay(false)}/>
      </div>
      <Container maxWidth="md" style={{ gap: '20px', marginTop: '50px' }}>
        <ImageList variant="masonry" cols={isOnTablet ? 1 : 2} gap={30} style={{ overflow: 'hidden' }}>
          {itemData.map((item, index) => (
            <ImageListItem
              key={item.img}
              style={{ border: `solid 2px ${hoverIndex === index ? 'rgb(46, 213, 255)' : 'rgba(255, 255, 255, 0.8)'}`, borderRadius: '5px', overflow: 'hidden' }}
                className='img-container'
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
                <div className={`overlay fadeInOut`} onClick={() => showOverlay(item)}>
                    <h1>{item.title}</h1>
                </div>
              <img
                srcSet={item.img}
                src={item.img}
                alt={item.title}
                className='img'
                loading="lazy"
                style={{ width: '100%', height: '100%', objectFit: 'cover', transition: 'transform 0.3s ease-in-out' }}
                onClick={() => showOverlay(item)}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Container>
    </div>
  );
}


const itemData = [
    {
      img: solusPrev,
      title: 'Solus',
      description: 'As a passionate musician and software engineer, I embarked on a journey to merge my two worlds – music and technology. The result? A groundbreaking Guitar Amp VST (Virtual Studio Technology) crafted with precision and innovation using the versatile JUCE framework in C++.',
      imgArr: [
        { img: solusport, prev: solusPortPrev },
        { img: soluspedalsport, prev: solusPedalsPortPrev },
        { img: cabPort, prev: cabPortPrev }
      ],
      features: [
        'Real-time audio processing',
      'Customizable tone shaping',
      'Clean and distorted channels',
      'Delay, Reverb and Gate pedals',
      'Custom impulse response control',
      'Dynamic user interface',
      'Low-latency audio playback'
    ],
    technologies: [
        'C++',
        'JUCE Framework',
        'Adobe Photoshop',
        'Inno Setup'
    ],
    github: 'https://github.com/jeffmcgwn/solus'
    },
    {
      img: bballPrev,
      title: 'Basketball Stats',
      description: 'Basketball Stats is a web application created for basketball coaches to make player highlight images without needing to use photo editing software. The user-friendly UI allows the user to enter stats for their players and customize the image to their liking.',
      imgArr: [
        { img: bball1, prev: bballPrev },
        { img: bball2, prev: bball2Prev },
        { img: bball3, prev: bball3Prev }
      ],
      features: [
        'User-friendly UI',
        'Customization of fonts, colors, images, and stats',
        'Downloadable product'
        ],
        technologies: [
            'React',
            'Bootstrap',
            'Adobe Photoshop',
        ],
        github: 'https://github.com/jeffmcgwn/basketballstats'
    },
    {
        img: lamePrev,
      title: 'Lame Genie',
      description: 'Website for the band Lame Genie. It has a client view and an admin view. The client view is a user-friendly UI that displays the band\'s music, videos, and upcoming shows. The admin view allows the band to update the website with new music, videos, and shows. The website is built with React, ExpressJS, and REST API. The admin view is protected with Auth0.',
      imgArr: [
        { img: lgsite1, prev: lgsite1Prev },
        { img: lgsite2, prev: lgsite2Prev }
      ],
      features: [
        'User-Friendly UI',
      'Admin functionality'
    ],
    technologies: [
        'React',
        'ExpressJS',
        'REST API',
        'Bootstrap',
        'Auth0'
    ],
    github: 'https://github.com/jeffmcgwn/lame-site'
    },
    {
        img: wrasslinPrev,
      title: 'Wrasslin',
      description: 'Wrasslin is an application that simulates wrestling matches between two players. Users can do quick matches with live or they can challenge their friends via the Wrasslin network. The application is built with React, Parse, and ExpressJS. The application uses Parse for the backend and ExpressJS for the server.',
      imgArr: [
        { img: wrasslin1, prev: wrasslinPrev },
        { img: wrasslin2, prev: wrasslin2Prev },
        { img: wrasslin3, prev: wrasslin3Prev },
        { img: wrasslin4, prev: wrasslin4Prev }
      ],
      features: [
        'Custom create-a-wrestler',
        'Network play',
        'Quick matches',
        'Leaderboard',
        'User profiles',
        'Menu to customize match settings',
        'Volume control'
    ],
    technologies: [
        'React',
        'Bootstrap',
        'Parse',
        'ExpressJS',
        'Adobe Photoshop',
    ],
    github: 'https://github.com/jeffmcgwn/react-wrasslin'
    }
  ];
  